*,*::before,*::after{
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: 0.4S;
}

/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Decrease the width of the scrollbar */
  height: 8px;
}

::-webkit-scrollbar-track {

  background: rgba(255, 255, 255, 0.9); /* Optional: Change the background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6); /* Change the color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Round the edges of the scrollbar thumb */
}

/* For Firefox */
* {
  scrollbar-width:10px; /* Decrease the width of the scrollbar */
  scrollbar-color: white; /* Set the color of the scrollbar thumb and track */
}




:root{
  --primaryColor:black;
  --navBackgroundColor:#F4EFEB;
  --mainBackgroundColor:#F8F4E9;
  --navFontColor:#557E8E;
  --navSecondaryFontColor:#4d717f;
  --aboutbackgroundcolor: #F5DCDA;
  --eventCardBackgroundColor:#ECE0DC;
  --sectionbackground: #F6E4DE;
  --imagebackground: #EEECE6;
  --footertextcolor: #FFFFFF;
  --logocolor:#CFA139;
  --logocolorbook: #315B5A;
  --bottomNavColor:#dad6cc;
}
html,body{
  width: 100%;
  height: 100%;
}
html{
  -webkit-text-size-adjust: 100%; -moz-text-size-adjust: 100%;-ms-text-size-adjust: 100%;
}
html:focus-within{scroll-behavior: smooth;}
body{
  font-family: sans-serif;
  line-height: 20px;
}

:root{
  width: 100%;
  height: 100%;
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex: 1;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-end{align-items: end;}
.align-center{align-items: center;}
.justify-center{justify-content: center;}
.pdng-left{padding-left: 1%;}
.pdng-right{padding-right: 1%;}
.margin-right{margin-right: 1%;}
.margin-top{margin-top: 1%;}
.text-shadow{text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);}
.drop-shadow{filter: drop-shadow( 0px 10px 10px rgba(0, 0, 0,0.2));}
.navlink-text{text-decoration: none;}
.pdngLeftRight{padding:0 1%;}
.mrgn-top-bottom{margin: 2% 0%;}
.margin-bottom{margin-bottom: 1%;}
.heading-text{
    color: var(--primaryColor);
    font: normal 600 16px 'Fahkwang';
    text-transform: uppercase;
}
.text-size{font-size: 12px;}
.inset-box-shadow{box-shadow:inset 0px 0px 20px rgba(0, 0, 0, 0.329)}
.box-shadow{box-shadow: 0px 10px 20px rgba(0, 0, 0)}

.flex-minWidth-row{
  display: flex;
  flex: 1;
  min-width: 0;
}
.flex-auto-row{
  display: flex;
  flex: none;
  min-width: 40px;
}
.flex-col{
  display: flex;
  flex-direction: column;
  flex: 0;
}

.pdng8-all
{
  padding: 8px;
}
.pdng-top-bottom{
  padding: 10px 0 10px 0;
}
.text-color
{
  color: var(--primaryColor);
}
.text-align-justify{
  text-align: justify;
}
.logo_text_color
{
  color: var(--navFontColor);
}
.heading-text{
  color: var(--logocolorbook);
}
.main-heading-text
{
  font-size: 14px;
  font-weight: 700;
}
.pointer
{
  cursor: pointer;
}
@media (min-width:700px) and (max-width:1023px){
  .text-size{
    font-size: 14px;
  }
  .main-heading-text{
    font-size: 18px;
  }
  .heading-text{
    font-size: 20px;
  }
}
@media (min-width:1024px){
  .text-size{
    font-size: 16px;
  }
  .main-heading-text{
    font-size: 20px;
  }
  .heading-text{
    font-size: 24px;
  }
}