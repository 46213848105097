.lang-container{
    position: fixed;
    right: 1rem;
    bottom: 9rem;
    background: linear-gradient(135deg, #e0eafc, #cfdef3);
    color: black;
    border: none;
    cursor: pointer;
    z-index: 1000; 
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    
}

.lang-selector{
  font-weight: 600;
    width: fit-content;
    height: 40px;
}
.lang-selector .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent; /* Remove border */
    }
  }
  
  .lang-selector .MuiOutlinedInput-root:hover fieldset {
    border-color: transparent; /* Remove border on hover */
  }
  
  .lang-selector .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: transparent; /* Remove border when focused */
  }
  /* Hide the default select arrow */
/* Remove the arrow from the Select component */
.lang-selector .MuiSelect-select {
  margin-right: -20px; 
  display: flex;
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  text-align: center; /* Center the text */
}

/* Optionally, center the icon if it's still visible or adjust its position */
.lang-selector .MuiSelect-icon {
  display: none;
  right: 0; /* Move the icon to the right if necessary */
}



.lang-icon{
  width: 30px;
  height: 30px;
  margin-top: -5%;
  margin-left: -5%;
}  

  @media (min-width:1000px){

    .lang-container{
      bottom: 5rem;
    }
  }