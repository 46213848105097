.open-popup-btn {
  position: fixed;
  right: 1rem;
  bottom: 5rem;
  padding: 2%;
  background-color: rgb(209, 233, 189);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000; 
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.6);
  }
  .open-popup-btn img{
    width: 100%;
    height: 30px;
  }
  
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;

  }
  
  .popup {
    background: linear-gradient(-25deg,#bee1c5, white,#d2f8d9,white);
    color: var(--primaryColor);
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 400px;
    max-width: 80%;
  }
  .close-group{
    margin: 0;
    padding: 1%;
    color: white;
    float: right;
    justify-content: end;
    align-self:flex-end;
  }
  
  .close-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding:3px 8px;
    border-radius: 5px;
    font-size: 15px;
    z-index: 10000;
    font-weight: 600;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.277);
  }
  .close-btn:hover{
    background-color: red;
    cursor: pointer;
  }
  
  .fdbk-form{
    width: 100%;
    z-index: 999;
  }
  .fdbk-form hr{
    border: 0.7px solid rgba(85, 82, 82, 0.299);
  }
 .heading{
  color: var(--primaryColor);
  font: normal 600 20px 'Arial';
  text-transform: uppercase;
 }
 .form-container{
  text-align: left;
  padding:2% 5%;
  row-gap: 10px;
 }
 .label{
  font: normal 500 14px 'Arial';
 }
  .input{
  background-color: #f5f5f5;
  color: var(--primaryColor);
  padding: 10px 5px;
  border-radius: 10px;
  outline: none;
  transition: 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.085);
  box-shadow: 0px 10px 20px -15px;
  font-family: Arial, Helvetica, sans-serif;
}
  .input::placeholder{
    font: normal normal 13px 'Arial';
  }

.input:focus {
  border-bottom: 2px solid #5b5fc7;
  border-radius: 10px 10px 2px 2px;
  box-shadow: 0px 15px 30px -15px;
}

  .submit-btn{
    box-shadow: 0px 10px 20px -2px rgba(0, 0, 0, 0.5); 
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    width: fit-content;
    font: normal 600 14px 'Arial';
    border-radius: 10px;
    background:linear-gradient(#31c955,#1c8835);
    color: white;
    transition:  0.4s linear;
  
  }
  
  .submit-btn:hover{
    background:linear-gradient(#2cb44b,#18742d);
    }
 


 .submitted-content{
  padding: 5%;
  font-size: 16px;
 }

 .rating-card {
  width: 280px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  padding: 20px 20px;
  border-radius: 10px;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.058);
}

.rating-stars-container {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items:start;
  justify-content: left;
}
.star-label svg {
  fill: rgb(195, 191, 191);
  width: 25px;
  height: auto;
}
.rating-stars-container input {
  appearance: unset;
}
.rating-stars-container input:hover ~ .star-label svg {
  fill: rgb(255, 250, 185);
}

.rating-stars-container input:checked ~ .star-label svg {
  fill: rgb(249, 193, 39);
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}
  @media (min-width:500px) and (max-width:999px){
    .open-popup-btn{
      /* bottom: 1rem; */
      padding: 0.6%;
    }
    .open-popup-btn img{
      width: 100%;
      height: 30px;
    }
  }

  @media (min-width:1000px){
    .open-popup-btn{
      bottom: 1rem;
      padding: 0.6%;
    }
    .open-popup-btn img{
      width: 100%;
      height: 35px;
    }
  }