.App{
  width: 100%;
  height: 100%;
}

.main{
  background-color: aliceblue;
  text-align: center;
  padding: 50px 0 0 0;
  color: red;
}
.footer{
  color: aliceblue;
  text-align: center;
  
}

@media (min-width:700px) and (max-width:1024px) {
  .main{
      padding: 55px 0 0 0; 
  }
}

@media (min-width:1024px) {
  .main{
    padding: 60px 0 0 0; 
  }
}