.bottom-nav-bar{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--bottomNavColor);
    height: 60px;
    z-index: 999;
    transform: translateY(0);
    transition: 'opacity 0.5s ease, transform 0.5s ease';
}
.bottom-nav-icons{
    column-gap: 8%;
    width: 100%;
    border-bottom: 5px solid black;
}
.bottom-nav-icons .icon{
    width: 40px;
    height: 40px;
    
}
.bottom-nav-btn{
    outline: none;
    border: none;
    background: none;
}

.selected-btn {
  transform: scale(1.2) translateY(1px);
}

.Home-img{
    width: 50px;
}
