/* Full-page loader wrapper */
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(228, 223, 223, 0.6); /* Backdrop shadow */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it sits above everything else */
}

/* Circle loader */
.circle-loader {
    border: 8px solid rgba(255, 255, 255, 0.2);
    border-top: 8px solid #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

/* Animation for the circle loader */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
