.main{
    background-color: var(--mainBackgroundColor);
    padding-bottom: 60px;
}

@media (min-width:1000px) {
    .main{
        padding-bottom: 0;

    }
  }   