.navbar{
    width: 100%;
    height: 50px;
    transition: 0.5s;
    background-color: var(--navBackgroundColor);
    box-shadow: 0px 1px 20px rgb(0, 0, 0,0.5);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
}

.sda-logo{
    width: 45px;
    height: 45px;
}

.navigation{
    column-gap: 30px;
    padding-left: 20%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.location-group .nav-tooltip{
    background: linear-gradient(135deg, #f5f5dc, #e6e6e6);

    color:black;
}
.location-group .location-tooltip{
    background: linear-gradient(135deg, #89f7fe, #66a6ff);
    color: #000000;
    
}
.location-group .tooltip{
    box-shadow: 0px 5px 10px -2px;
    opacity: 1;
    font-weight: 600;
    border-radius: 10px;
}
.nav-btn{
    border-radius: 8px;
    padding:1%;
    text-transform: capitalize;
    outline: none;
    font-weight: 500;
    border: none;
}

.nav-btn:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.location{
    display: grid;

}
.church-area{
    text-align: end;
    color: var(--navFontColor);
    font: normal 400 8px 'Fahkwang';
}

.church-name{
    color: var(--navSecondaryFontColor);
    text-align: right;
    position: relative;
    text-transform: uppercase;
    font: normal 600 10px 'Fahkwang';
}

.location-icon{
    display: grid;
    padding-top: 0.9%;
}
.location-icon .icon{
    width: 12px;
    height: fit-content;
}

@media (min-width:700px) and (max-width:1024px) {
    .navbar{
        height: 55px; 
    }
    .church-name{
        font-size: 14px;
    }
    .church-area{
        font-size: 10px;
    }
   
    .location-icon .icon{
        width: 18px;
        height: fit-content;
    }
    .navigation{
        padding-left:15% ;
    }
}

@media (min-width:1024px){
    .navbar{
        height: 60px;
    }
    .sda-logo{
        width: 60px;
        height: 60px;
    }
    .church-name{
        font-size: 16px;
    }
    .church-area{
        font-size: 12px;
    }
    
    .location-icon .icon{
        width: 18px;
        height: fit-content;
    }
}

